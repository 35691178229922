import { blueColor, lightBlueColor, textColor } from "@/constants/colors";
import { blogCategoryPath } from "@/constants/paths";
import { css } from "@emotion/react";
import Link from "next/link";
import { H9 } from "../Typography";

interface ChipProps {
  option: {
    value: string;
    label: string;
  };
  selectedValue: string;
}

const Chip = ({ option, selectedValue }: ChipProps) => {
  const isSelected = selectedValue === option.value;
  return (
    <Link
      css={css`
        pointer-events: ${isSelected ? "none" : "auto"};
        cursor: ${isSelected ? "default" : "pointer"};
      `}
      href={blogCategoryPath(option.value, 1)}
    >
      <H9
        css={css`
          color: ${isSelected ? "white" : textColor};
          background-color: ${isSelected ? lightBlueColor : "transparent"};
          border-radius: 10px;
          padding: 11px 10px;
          border: 2px solid transparent;
          transition: all 0.2s ease-in-out;
          &:hover {
            border-color: ${blueColor};
          }
        `}
      >
        {option.label}
      </H9>
    </Link>
  );
};

export default Chip;
