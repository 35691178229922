import { css } from "@emotion/react";
import ArrowButton from "../Buttons/ArrowButton";
import ReactPaginate from "react-paginate";
import {
  blogAuthorPath,
  blogCategoryPath,
  blogIndexPath,
} from "@/constants/paths";
import { useRouter } from "next/router";
import { lightBlueColor, textColor } from "@/constants/colors";
import { gotham } from "@/utils/fonts";
import { H9Css } from "../Typography";
import { mq } from "@/constants/breakpoints";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
}

const Pagination = ({ currentPage, totalPages }: PaginationProps) => {
  const { push, query } = useRouter();

  const handlePageClick = (data: any) => {
    if (query.category) {
      push(blogCategoryPath(query.category as string, data.selected + 1));
    } else if (query.author) {
      push(blogAuthorPath(query.author as string, data.selected + 1));
    } else {
      push(blogIndexPath(data.selected + 1));
    }
  };

  const hrefBuilder = (page: number) => {
    if (query.category) {
      return blogCategoryPath(query.category as string, page);
    } else if (query.author) {
      return blogAuthorPath(query.author as string, page);
    } else {
      return blogIndexPath(page);
    }
  };

  return (
    <div>
      <ReactPaginate
        css={css`
          display: flex;
          list-style: none;
          gap: 5px;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 60px;
          .previous {
            flex: 100;
          }
          .next {
            flex: 100;
          }
          li:not(.previous, .next) > a {
            width: 30px;
            height: 30px;
            color: ${textColor};
            display: flex;
            align-items: center;
            justify-content: center;
            ${H9Css}
          }
          .selected > a {
            background-color: ${lightBlueColor};
            border-radius: 5px;
            color: #fff !important;
          }
          ${mq["lg"]} {
            padding-bottom: 110px;
          }
        `}
        hrefBuilder={hrefBuilder}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageClassName={gotham.className}
        pageCount={totalPages}
        previousLabel={
          <ArrowButton
            direction="left"
            css={css`
              float: left;
            `}
          />
        }
        nextLabel={
          <ArrowButton
            direction="right"
            css={css`
              float: right;
            `}
          />
        }
        forcePage={currentPage - 1}
      />
    </div>
  );
};

export default Pagination;
