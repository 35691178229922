import { GetStaticProps } from "next";
import BlogIndexPage from "@/components/BlogIndex/BlogIndexPage";
import { getBlogItems } from "@/utils/getBlogItems";

const Page = ({ ...props }: any) => {
  return <BlogIndexPage {...props} />;
};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: await getBlogItems(1),
  };
};

export default Page;
