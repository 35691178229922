import { css } from "@emotion/react";
import Pagination from "../BlogShared/Pagination";
import Container from "../Container/Container";
import Layout from "../Layout/Layout";
import { Heading } from "../Typography";
import BlogPost from "./BlogPost";
import "react-dropdown/style.css";
import JWDropdown from "../JWDropdown/JWDropdown";
import { useMemo } from "react";
import { mq } from "@/constants/breakpoints";
import Chips from "../BlogShared/Chips";
import { useRouter } from "next/router";
import { blogCategoryPath } from "@/constants/paths";

interface BlogIndexPageProps {
  blogPosts: any[];
  categories: any[];
  totalPageCount: number;
  currentPage: number;
}

const BlogIndexPage = ({
  blogPosts,
  categories,
  totalPageCount,
  currentPage,
}: BlogIndexPageProps) => {
  const { asPath, push, query } = useRouter();

  const title = useMemo(() => {
    if (query.category) {
      const categoryName = categories.find(
        (c) => c.slug === query.category
      ).category;
      return `Category: ${categoryName}${
        currentPage === 1 ? "" : ` - Page ${currentPage}`
      }`;
    } else if (query.author) {
      return `Author: ${blogPosts[0].author.name}${
        currentPage === 1 ? "" : ` - Page ${currentPage}`
      }`;
    } else {
      return `Youth Sports Management Blog${
        currentPage === 1 ? "" : ` - Page ${currentPage}`
      }`;
    }
  }, [query, blogPosts, currentPage, categories]);

  const selectedCategory = useMemo(() => {
    if (asPath.startsWith("/blog/category/")) {
      const categorySlug = asPath.split("/")[3];
      const category = categories.find(
        (category) => category.slug === categorySlug
      );
      return {
        value: categorySlug,
        label: category.category,
      };
    } else {
      return {
        value: "all",
        label: "All Categories",
      };
    }
  }, [asPath, categories]);

  const categoryOptions = useMemo(() => {
    const map = categories.map((category) => ({
      value: category.slug,
      label: category.category,
    }));
    return [
      { value: "all", label: "All Categories" },
      ...map.sort((a, b) => (a.label > b.label ? 1 : -1)),
    ];
  }, [categories]);

  return (
    <Layout
      title={`Youth Sports Management Blog - Page ${currentPage}`}
      metaDescription={`A great resource for youth sports management advice, company news, product updates, and more.${
        currentPage === 1 ? "" : ` Page ${currentPage}.`
      }`}
      heroContainerCss={css`
        background: url(/images/blog/index-bg.svg),
          linear-gradient(112.24deg, #048cdd 4.51%, #32b2ff 100.81%);
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: center;
      `}
      hero={
        <Container>
          <Heading
            level={1}
            css={css`
              padding-top: 24px;
              color: white;
              text-align: center;
              padding-bottom: 54px;
            `}
          >
            {title}
          </Heading>
        </Container>
      }
    >
      {query.author ? (
        <div
          css={css`
            margin-top: 40px;
            ${mq["lg"]} {
              margin-top: 80px;
            }
          `}
        />
      ) : (
        <Container
          css={css`
            margin-top: 40px;
          `}
        >
          <>
            <div
              css={css`
                display: block;
                ${mq["lg"]} {
                  display: none;
                }
              `}
            >
              <JWDropdown
                options={categoryOptions}
                value={selectedCategory}
                onChange={(option) => {
                  push(blogCategoryPath(option.value, 1));
                }}
              />
            </div>
            <Chips
              options={categoryOptions}
              selectedValue={selectedCategory.value}
            />
          </>
        </Container>
      )}
      <Container
        css={css`
          padding-top: 24px;
          display: grid;
          grid-template-columns: 1fr;
          gap: 60px;
          ${mq["lg"]} {
            grid-template-columns: 1fr 1fr;
            grid-gap: 80px 42px;
            padding-top: 40px;
          }
          ${mq["xl"]} {
            grid-gap: 110px 42px;
          }
        `}
      >
        {blogPosts.map((item) => (
          <BlogPost key={item.slug} item={item} />
        ))}
      </Container>
      <Container>
        <div
          css={css`
            margin-top: 60px;
            ${mq["lg"]} {
              margin-top: 120px;
            }
          `}
        >
          <Pagination currentPage={currentPage} totalPages={totalPageCount} />
        </div>
      </Container>
    </Layout>
  );
};

export default BlogIndexPage;
