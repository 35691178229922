import { mq } from "@/constants/breakpoints";
import { css } from "@emotion/react";
import Chip from "./Chip";

interface ChipsProps {
  options: {
    value: string;
    label: string;
  }[];
  selectedValue: string;
}

const Chips = ({ options, selectedValue }: ChipsProps) => {
  return (
    <div
      css={css`
        display: none;
        ${mq["lg"]} {
          display: flex;
          flex-wrap: wrap;
          gap: 12px 8px;
        }
      `}
    >
      {options.map((option) => (
        <Chip
          key={option.value}
          option={option}
          selectedValue={selectedValue}
        />
      ))}
    </div>
  );
};

export default Chips;
