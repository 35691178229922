import { lightBlueColor } from "@/constants/colors";
import { gotham } from "@/utils/fonts";
import Dropdown, { Option } from "react-dropdown";

interface JWDropdownProps {
  options: Option[];
  value: Option;
  placeholder?: string;
  // eslint-disable-next-line
  onChange: (arg: Option) => void;
}

const JWDropdown = ({
  options,
  value,
  placeholder,
  onChange,
}: JWDropdownProps) => {
  return (
    <>
      <Dropdown
        options={options}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className={gotham.className}
      />
      <style global jsx>
        {`
          .Dropdown-root {
            width: 250px;
            font-weight: 350;
            font-size: 12px;
            line-height: 14px;
          }
          .Dropdown-control {
            border: unset;
            color: ${lightBlueColor};
            cursor: pointer;
          }
          .Dropdown-arrow-wrapper {
            display: none;
          }
          .Dropdown-menu {
            padding: 10px 20px;
            margin-top: 10px;
            border: unset;
            box-shadow: 0px 8px 30px rgba(116, 134, 196, 0.24);
            border-radius: 12px;
          }
          .Dropdown-option {
            padding: 10px 0;
          }
          .is-selected {
            background-color: #fff !important;
            color: ${lightBlueColor} !important;
          }
          .Dropdown-placeholder {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .Dropdown-placeholder::before {
            content: url("data:image/svg+xml, %3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 11L12 14L8 11' stroke='%23048CDD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            width: 24px;
            height: 24px;
          }
        `}
      </style>
    </>
  );
};

export default JWDropdown;
