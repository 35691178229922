import { mq } from "@/constants/breakpoints";
import {
  blueColor,
  darkColor,
  darkGreenColor,
  mutedGreenColor,
  textColor,
} from "@/constants/colors";
import { blogPostPath } from "@/constants/paths";
import { css } from "@emotion/react";
import Link from "next/link";
import ContentfulImage from "../ContentfulImage/ContentfulImage";
import { BodyRegular14, Heading } from "../Typography";

interface BlogPostProps {
  item: any;
}

const BlogPost = ({ item }: BlogPostProps) => {
  return (
    <Link
      href={blogPostPath(item.slug)}
      css={css`
        ${mq["lg"]} {
          margin: -20px;
          border: 2px solid transparent;
          border-radius: 20px;
          padding: 20px;
          transition: border-color 0.2s ease-in-out;
          &:hover {
            border-color: ${blueColor};
          }
        }
      `}
    >
      <ContentfulImage
        src={item.featuredMedia.url}
        alt={item.featuredMedia.title}
        width={item.featuredMedia.width}
        height={item.featuredMedia.height}
        css={css`
          max-width: 100%;
          height: auto;
          border-radius: 12px;
          margin-bottom: 30px;
        `}
      />
      <div
        css={css`
          display: flex;
          gap: 12px;
        `}
      >
        {item.categoriesNewCollection.items.map((category: any) => (
          <Heading
            key={category.slug}
            css={css`
              font-weight: 350;
              font-size: 12px;
              line-height: 14px;
              padding: 6px 13px;
              background-color: ${mutedGreenColor};
              color: ${darkGreenColor};
              border-radius: 40px;
              margin-bottom: 14px;
            `}
          >
            {category.category}
          </Heading>
        ))}
      </div>
      <Heading
        level={2}
        css={css`
          margin-bottom: 12px;
          color: ${darkColor};
        `}
      >
        {item.title}
      </Heading>
      <BodyRegular14
        css={css`
          color: ${textColor};
        `}
      >
        {item.summary}
      </BodyRegular14>
      <Heading
        css={css`
          font-weight: 350;
          font-size: 12px;
          line-height: 14px;
          margin-top: 16px;
          color: ${darkColor};
        `}
      >
        {item.author.name}
      </Heading>
    </Link>
  );
};

export default BlogPost;
